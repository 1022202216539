import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';

export interface IDefaultProductPriceChange extends IBaseFranchiseEntity {
    id?: number;
    updatedDate?: string;
    updatedBy?: any;
    product?: any;
    customer?: any;
    newPrice: number;
    oldPrice?: number;
}

export class DefaultProductPriceChange extends BaseFranchiseEntity implements IDefaultProductPriceChange {
    public id: number;
    public updatedDate: string;
    public updatedBy: any;
    public product: any;
    public customer: any;
    public newPrice: number;
    public oldPrice: number;

    constructor(productCostChange?) {
        super();
        if (productCostChange) {
            this.id = productCostChange.id;
            this.updatedBy = productCostChange.updatedBy;
            this.product = productCostChange.product;
            this.updatedDate = productCostChange.updatedDate;
            this.newPrice = productCostChange.newPrice;
            this.oldPrice = productCostChange.oldPrice;
            this.customer = productCostChange.customer;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): DefaultProductPriceChange {
        return new DefaultProductPriceChange(this);
    }
}
